<script setup lang="ts">
const props = defineProps<{
  result: App.Bulkinator.Data.DataImportResultData;
}>();

const errorMessage = computed(() => {
  return Object.values(props.result.message).join(' ');
});
</script>

<template>
  <div class="border-b border-gray-200 p-2 text-sm odd:bg-gray-50">
    <span class="italic">Row {{ result.row_index + 2 }}:</span> {{ errorMessage }}
  </div>
</template>
